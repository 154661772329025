<template>
  <div class="flex flex-col justify-center items-center" :class="height">
    <div class="my-5 title text-xl">{{ text }}</div>
    <div class="">
      <div
        class="w-12 h-12 rounded-full animate-spin border border-solid border-[#581845] border-t-transparent"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading-spinner",
  props: {
    height: { type: String, default: "h-[60vh]" },
    text: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped></style>
