import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueSmoothScroll from "vue3-smooth-scroll";
import store from "./store";
import "@/assets/styles/main.css";
import "@/assets/styles/index.css";
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "./components/Spinner.vue";
import PaymentSuccess from "./components/PaymentSuccess.vue";

const app = createApp(App);
app.component("lazy-loading", Spinner);
app.component("payment-success", PaymentSuccess);
app.use(VueTelInput);
app.use(VueSmoothScroll);
app.use(store);
app.use(router);
app.use(Vue3Toasity);
app.mount("#app");
