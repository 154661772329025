<template>
  <div class="bg-slate-200 md:min-h-[60vh]">
    <div
      class="title text-[40px] xs:text-[25px] text-center text-[#581845] font-bold py-10"
    >
      Our testimonies
    </div>

    <div class="py-10 flex justify-center items-center">
      <Swiper
        ref="swiperContainer"
        :slidesPerView="isMobile ? 2 : 1"
        :spaceBetween="30"
        :autoplay="{ disableOnInteraction: true }"
        :loop="true"
        class="w-[50%] mx-auto xs:w-full xs:px-8 relative"
      >
        <SwiperSlide v-for="(item, i) in testimonies" :key="i">
          <div class="">
            <i class="text-[#581845] fas fa-quote-left text-[50px]"></i>
            <div class="mt-4">{{ item.content }}</div>
          </div>

          <div class="flex items-center font-bold mt-5">
            <div
              class="w-10 h-10 bg-[#581845] rounded-full flex items-center justify-center text-white font-bold mr-5"
            >
              {{ item.name.charAt(0) }}
            </div>
            {{ item.name }}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="flex gap-2 justify-center pb-8">
      <button
        @click="handlePrev"
        class="w-7 h-7 flex justify-center items-center"
      >
        <i class="fas fa-angle-left"></i>
      </button>
      <button
        @click="handleNext"
        class="w-7 h-7 flex justify-center items-center"
      >
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, ref, computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css/pagination";
import "swiper/css";

export default defineComponent({
  name: "_header",
  components: { Swiper, SwiperSlide },

  data() {
    return {
      testimonies: [
        {
          name: "Cedric M.",
          content: `Using Tori from HOVA AI has been an incredible experience. The AI's ability to
understand my questions and generate helpful responses is truly impressive.`,
        },
        {
          name: "Aline K.",
          content: `Thanks to Tori from HOVA AI, I was able to improve my language skills in a fun
and engaging way. The AI provided me with tailored language lessons and
conversation practice that have helped me feel more confident when speaking.
`,
        },
        {
          name: "Honore I.",
          content: `I was struggling with writer's block and needed some inspiration. HOVA AI's Tori
was able to generate an amazing array of creative prompts that got my creative
juices flowing and helped me break through my creative slump.
 `,
        },
        {
          name: "Gilbert K.",
          content: `As someone who enjoys learning about various topics, I have found Tori from HOVA
AI to be an incredibly useful resource. Whether I need help with my schoolwork
or just want to learn something new, the AI always provides me with helpful and
informative responses.
 `,
        },
        {
          name: "Jane U.",
          content: `HOVA AI's Tori has been a game-changer for our business. The AI's ability to
analyze and provide insights on our retail operations has been invaluable and
has helped us make data-driven decisions that have improved our bottom line.
`,
        },
      ],
    };
  },
  setup() {
    const screen = ref(window.innerWidth);
    const swiperContainer = ref(null);

    const handleNext = () => {
      swiperContainer.value.$el.swiper.slideNext();
    };

    const handlePrev = () => {
      swiperContainer.value.$el.swiper.slidePrev();
    };
    watchEffect(() => {
      window.addEventListener("resize", () => {
        screen.value = window.innerWidth;
      });
    });

    const isMobile = computed(() => screen.value > 768);

    return {
      screen,
      modules: [Autoplay, EffectFade],
      handleNext,
      handlePrev,
      swiperContainer,
      isMobile,
    };
  },
});
</script>

<style lang="scss" scoped></style>
