<template>
  <div class="bg-[#581845] text-white py-10">
    <div class="w-[80%] mx-auto xs:w-full xs:px-3">
      <div class="flex justify-between flex-wrap sm:gap-[50px]">
        <div class="md:w-[25%] w-full">
          <img src="@/assets/logo.png" class="h-6 mr-2" alt="Flowbite Logo" />
          <div class="mt-4">
            Tori, the ChatGPT powered bot running on Whatsapp at
            <a class="underline" href="https://wa.me/+250737829991"
              >+250 737 829 991
            </a>
            ! I can help you with a wide range of queries and concerns concern.
          </div>
        </div>
        <div class="flex flex-col">
          <div class="uppercase font-bold title">Quick Links</div>
          <a class="text-gray-300 mt-4" href="https://wa.me/+250737829991"
            >Pricing
          </a>
          <a class="text-gray-300 mt-3" href="https://wa.me/+250737829991"
            >Useful Commands </a
          ><a class="text-gray-300 mt-3" href="https://wa.me/+250737829991"
            >Testiomonies
          </a>
        </div>
        <div class="flex flex-col">
          <div class="uppercase font-bold title">Join our community</div>
          <a class="text-gray-300 mt-4" href="https://chat.whatsapp.com/Bu64BlqMyhtGOY9TvBZpHe"
            >Join community group
          </a>
          <a class="text-gray-300 mt-4" href="https://chat.whatsapp.com/K0DY2fI5gqkKghlsSGcsBf"
            >Join general group
          </a>
        </div>
        <div class="flex flex-col">
          <div class="uppercase font-bold title">Follow us</div>
          <div class="flex gap-5">
            <a class="text-gray-300 mt-4" href="https://twitter.com/torihova"
              ><i class="fab fa-twitter"></i>
            </a>
            <a class="text-gray-300 mt-4" href="https://instagram.com/torihova"
              ><i class="fab fa-instagram"></i>
            </a>
            <a class="text-gray-300 mt-4" href="https://www.linkedin.com/company/hova-ai"
              ><i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg-[#581845] text-center text-xl text-white">
      Powered by <a href="https://hova.ai">hova.ai</a>
    </div> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "_header",
  data() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
