import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/PricingView.vue"),
  },
  {
    path: "/pricing/:plan",
    name: "plan",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "@/views/PlanView.vue"),
  },
  {
    path: "/transaction/:id",
    name: "transaction",
    component: () =>
      import(
        /* webpackChunkName: "transaction" */ "../views/TransactionView.vue"
      ),
  },

  {
    path: "/chat",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/ChatView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
