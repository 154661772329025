<template>
  <onboard id="home" />
  <pricing id="pricing" />
  <commands />
  <testimonies id="testimonies" />
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import Onboard from "@/components/Onboard.vue";
import Pricing from "@/components/Pricing.vue";
import Footer from "@/components/Footer.vue";
import Commands from "@/components/Commands.vue";
import Testimonies from "@/components/Testimonies.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Onboard,
    Pricing,
    Footer,
    Commands,
    Testimonies,
  },
  data() {
    return {
      loading: false,
      pricing: []
    }
  },
  mounted() {
    document.title = "Tori - Home";
  },
});
</script>
