<template>
  <lazy-loading v-if="loading" />
  <div
    v-else
    ref="pricing"
    id="pricing"
    class="bg-slate-200 px-5 xs:pt-5 pb-10 min-h-[70vh] flex flex-col justify-center"
  >
    <div class="text-center py-8">
      <div class="title text-[40px] xs:text-[25px] text-[#581845] font-bold">
        Plans & Pricing
      </div>
      <div class="w-full md:w-[50%] mx-auto my-5 text-gray-600 font-thin">
        "Tori's pricing options offer flexibility and affordability for
        businesses seeking chatbot solutions. Choose from a variety of plans to
        meet your needs and budget, without sacrificing quality."
      </div>
    </div>

    <div
      class="w-full md:w-[80%] mx-auto gap-5 md:grid md:grid-cols-3 py-8 flex justify-between overflow-auto"
      :class="`[&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`"
    >
      <div
        class="min-w-[300px] md:min-w-full"
        v-for="(item, i) in notFreepricing"
        :key="i"
      >
        <div
          v-if="item.title.toLowerCase() !== 'free'"
          class="xs:mb-7 bg-white rounded-xl px-4 pt-7 flex flex-col items-center min-h-[520px] relative"
          :class="{
            'border-4 border-[#581845]': item.is_popular,
          }"
        >
          <div class="uppercase font-semibold text-xxl tracking-widest">
            {{ item.title }}
          </div>
          <div class="mt-5">
            <span class="text-[25px] font-bold text-[#581845]"
              >{{ item.currency }} {{ item.price }}
            </span>
            <span
              class="text-[20px]text-gray-500"
              v-if="item.title.includes('free')"
            >
            </span>
            <span class="text-[20px] text-gray-500" v-else>
              / {{ item.count }}
            </span>
          </div>

          <div class="w-full md:w-[85%] mx-auto">
            <button
              v-if="!item.title.includes('free')"
              class="mt-5 bg-[#581845] text-white px-8 py-1 w-full hover:bg-gray-600"
              @click="
                this.$router.push(`/pricing/${item?.title?.toLowerCase()}`)
              "
            >
              Subscribe
            </button>

            <hr
              class="w-full h-[1px] mx-auto bg-[#581845] border-0 rounded my-7 xs:my-6"
            />

            <div class="mb-8">
              <div
                class="mt-2 text-[15px] flex items-start"
                v-for="i in item.details"
                :key="i"
              >
                <div class="mr-2">✔️</div>
                <div>{{ i }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "_header",
  props: ["pricing"],
  data() {
    return {
      showDrawer: false,
      notFreepricing: [],
      plan: null,
      freePricing: null,
      loading: false,
    };
  },
  methods: {
    toggleDrawer(selectedItem) {
      this.showDrawer = !this.showDrawer;
      this.plan = selectedItem;
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("getDeviceLocation").then((res) => {
      let userLocation = res?.country?.code ?? "RW";

      this.$store.dispatch("getPricing").then((res) => {
        this.loading = false;
        if (userLocation === "RW") {
          this.notFreepricing = res?.data?.reduce((accumulator, item) => {
            if (item.title.toLowerCase() !== "free") {
              accumulator.push({
                ...item,
                price: item.price.RWF,
                currency: "RWF",
              });
            }
            return accumulator;
          }, [])
        } else {
          this.notFreepricing = res?.data?.reduce((accumulator, item) => {
            if (item.title.toLowerCase() !== "free") {
              accumulator.push({
                ...item,
                price: item.price.USD,
                currency: "$",
              });
            }
            return accumulator;
          }, [])
        }

        this.freePricing = res?.data?.find(
          (item) => item.title.toLowerCase() === "free"
        );
      });
    });
  },
});
</script>

<style lang="scss" scoped></style>
