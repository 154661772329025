<template>
  <div>
    <div
      class="bg-white w-full h-[80vh] flex flex-col justify-center items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-20 h-20 text-[#581845]"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <div class="text-center">
        <div class="text-2xl text-[#581845]">Payment successful!</div>
        <div class="title text-[30px] mt-5">
          You have subscribed to
          <span class="text-[#581845] font-bold">{{ plan }}</span>
          plan.
        </div>
      </div>

      <button
        @click="this.$router.push('/')"
        class="text-[#581845] font-bold border-2 border-[#581845] rounded-[8px] py-2 px-6 my-7"
      >
        <i class="fas fa-arrow-left mr-4"></i>
        Back to home
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plan: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped></style>
