import { createStore } from "vuex";
import http from "../helper/axiosInstance";

export default createStore({
  state: {
    paymentInitiate: [],
    pricing: [],
    stats: [],
    pricingError: [],
    errors: [],
    paymentSuccess: "",
    loadingStats: false,
    loadingPricing: false,
    deviceLocation: [],
    chat_messages: [],
  },
  mutations: {
    INITIATE_PAYMENT(state, paymentInitiate) {
      state.paymentInitiate = paymentInitiate;
    },

    GET_PRICING(state, pricing) {
      state.pricing = pricing;
    },

    GET_STATS(state, stats) {
      state.stats = stats;
    },

    GET_CHAT_MESSAGES(state, messages) {
      state.messages = messages;
    },

    LOADING_STATS(state, loadingStats) {
      state.loadingStats = loadingStats;
    },

    GET_PRICING_ERROR(state, pricingError) {
      state.pricingError = pricingError;
    },

    LOADING_PAYMENT(state, loading) {
      state.loading = loading;
    },

    PAYMENT_ERROR(state, errors) {
      state.errors = errors;
    },

    PAYMENT_SUCCESS(state, paymentSuccess) {
      state.paymentSuccess = paymentSuccess;
    },

    DEVICE_LOCATION(state, deviceLocation) {
      state.deviceLocation = deviceLocation;
    },
  },
  getters: {
    getPaymentInitiate: (state) => state.paymentInitiate,
    getPricing: (state) => state.pricing,
  },
  actions: {
    initiatePayment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http()({
          url: "/pay/kpay/init_request",
          data: payload,
          method: "POST",
        })

          .then((response) => {
            commit("INITIATE_PAYMENT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("PAYMENT_ERROR", error?.response?.data?.message);
            reject(error.response);
          });
      });
    },
    checkPaymentStatus({ commit }, trans_id) {
      return new Promise((resolve, reject) => {
        http()({
          url: `/pay/kpay/transaction_status/${trans_id}`,
          method: "GET",
        })

          .then((response) => {
            commit("PAYMENT_SUCCESS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("PAYMENT_ERROR", error?.response?.data?.message);
            reject(error.response);
          });
      });
    },
    getPricing({ commit }) {
      return new Promise((resolve, reject) => {
        http()({
          url: `/pricing`,
          method: "GET",
        })
          .then((response) => {
            commit("GET_PRICING", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("GET_PRICING_ERROR", error?.response);
            reject(error.response);
          });
      });
    },
    getStats({ commit }) {
      commit("LOADING_STATS", true);
      return new Promise((resolve, reject) => {
        http()({
          url: `/stats`,
          method: "GET",
        })
          .then((response) => {
            commit("LOADING_STATS", false);
            commit("GET_STATS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("LOADING_STATS", false);
            commit("GET_PRICING_ERROR", error?.response);
            reject(error.response);
          });
      });
    },
    getDeviceLocation({ commit }) {
      return new Promise((resolve, reject) => {
        fetch("https://api.ipregistry.co/?key=q8hu8czau2ow5ph1")
          .then((response) => response.json())
          .then((payload) => {
            commit("DEVICE_LOCATION", payload?.location);
            resolve(payload?.location);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getChatMessages({ commit }) {
      return new Promise((resolve, reject) => {
        http()({
          url: `/messages`,
          method: "GET",
        })
          .then((response) => {
            commit("GET_CHAT_MESSAGES", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    deleteChatMessages() {
      return new Promise((resolve, reject) => {
        http()({
          url: `/messages`,
          method: "DELETE",
          // add cookies

        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    sendChatMessage(_,payload) {
      return new Promise((resolve, reject) => {
        http()({
          url: `/message`,
          method: "POST",
          data: payload,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    }
  },
  modules: {},
});
