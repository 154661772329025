<template>
  <nav class="bg-[#581845] sm:py-2 px-10 sm:px-5 sticky top-0 z-10 md:h-[60px]">
    <div class="flex flex-wrap items-center justify-between md:h-full mx-auto">
      <a href="/" class="flex items-center">
        <img src="@/assets/logo.png" class="h-6 mr-2" alt="Flowbite Logo" />
        <span
          class="self-center uppercase text-2xl font-semibold whitespace-nowrap text-white"
          >tori</span
        >
      </a>

      <button
        v-show="currentRoute.name === 'home'"
        data-collapse-toggle="navbar-default"
        type="button"
        class="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg md:hidden focus:outline-none border border-gray-100"
        aria-controls="navbar-default"
        aria-expanded="false"
        @click="toggleMenu"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="w-[150px] plan" v-show="currentRoute.name === 'plan'">
        <select
          v-model="selectedPlan"
          class="w-full xs:py-1 bg-gray-50 border-0 border-gray-300 text-gray-900 text-sm rounded-[5px] focus:ring-[#581845] focus:border-[#581845] block"
        >
          <option selected disabled>Choose a plan</option>
          <option v-for="item in pricings" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div
        class="w-full md:w-auto"
        id="navbar-default"
        :class="showMenu ? 'block' : 'hidden md:block'"
        v-show="currentRoute.name === 'home'"
      >
        <Transition name="slide-fade">
          <ul
            v-if="showMenuAnimation"
            class="flex flex-col border-gray-400 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0"
          >
            <li
              v-for="item in items"
              :key="item"
              @click="scrollToEl('#' + item.toLowerCase())"
              class="sm:border-b border-gray-400 sm:py-2 text-center"
              :class="
                currentRoute.hash === '#' + item.toLowerCase()
                  ? 'border-b sm:bg-gray-600'
                  : ''
              "
            >
              <a href="#" class="text-[16px] text-white" aria-current="page">{{
                item
              }}</a>
            </li>
          </ul>
        </Transition>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, watchEffect, ref, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "_header",
  data() {
    return {
      items: ["Home", "Pricing", "testimonies"],
      showMenuAnimation: true,
      showMenu: false,
      selectedPlan: "Choose a plan",
    };
  },
  methods: {
    scrollToEl(index) {
      const element = document.querySelector(index);

      this.$smoothScroll({
        scrollTo: element,
        duration: 1500,
        offset: -70,
        updateHistory: true,
      });
    },
    toggleMenu() {
      this.showMenuAnimation = false;
      setTimeout(() => {
        this.showMenuAnimation = true;
        this.showMenu = !this.showMenu;
      }, 100);
    },
  },
  computed: {
    pricings() {
      return this.$store.getters?.getPricing?.reduce((acc, item) => {
        if (item?.title?.toLowerCase() !== "free") {
          acc.push(item.title);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    selectedPlan(value) {
      this.$router.push(`/pricing/${value?.toLowerCase()}`);
      this.$store.dispatch("getPricing");
    },
  },
  setup() {
    const screen = ref();
    const route = useRoute();

    const currentRoute = computed(() => route);

    watchEffect(() => {
      window.addEventListener("resize", () => {
        screen.value = window.innerWidth;
      });
    });

    return { screen, currentRoute };
  },
});
</script>

<style lang="scss" scoped></style>
