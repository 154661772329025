import axios from "axios";

export default () => {
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV !== "production"
        ? "https://tori.hova.ai/api/v1"
        : "https://tori.hova.ai/api/v1",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  const requestHandler = (request) => {
    request.headers.Authorization = localStorage.token;
    return request;
  };

  const responseHandler = (response) => {
    return response;
  };

  const errorHandler = (error) => {
    return Promise.reject(error);
  };

  instance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  return instance;
};
