<template>
  <div class="md:px-[150px] px-8 sm:pt-8 pb-10 bg-[#581845] flex sm:flex-col-reverse justify-between items-center">
    <div class="w-[45%] sm:w-full">
      <div class="title text-[50px] xs:text-[30px] text-white sm:hidden">
        Tori by
        <a class="" href="https://hova.ai" target="_blank">hova.ai</a>
      </div>
      <div class="text-[18px] sm:text-center xs:text-[16px] text-gray-400 my-8 leading-8 font-thin">
        Welcome to Tori, the ChatGPT powered bot running on WhatsApp at
        <a class="underline italic" href="https://wa.me/+250737829991">+250 737 829 991</a>
        and on
        <a class="underline italic" href="https://t.me/toribyhova_bot">Telegram.</a>
        We can help you with a wide range of queries and concerns, and
        I'm always available to chat with you. I support voice notes, and can
        respond with speech! So go ahead and text me(or send a voice note😉)!
        I'm ready and waiting to assist you.
      </div>

      <div class="mb-10 flex sm:flex-col gap-8">
        <a class="text-white px-5 py-2" v-for="item in social" :href="item.link" :class="item.class" :key="item.link"
          :target="item.target || '_blank'">
          <i :class="item.icon" class="mr-3"></i>
          {{ item.title }}
        </a>
      </div>

      <div class="flex justify-between items-center md:w-[70%] sm:w-full">
        <div class="" v-for="(item, n) in stats" :key="n">
          <div class="font-bold text-white text-3xl tracking-widest">
            {{ item.title }}
          </div>
          <div class="text-white text-xl mt-2">
            {{ String(item.value).replace(/(.)(?=(\d{3})+$)/g, "$1,") }} +
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="hidden sm:block sm:mb-5 title text-[50px] xs:text-[30px] text-white text-center">
        Tori by
        <a class="" href="https://hova.ai" target="_blank">hova.ai</a>
      </div>
      <div
        class="xs:w-[260px] w-[320px] border-[25px] xs:border-[15px] shadow-xl rounded-3xl border-gray-100 h-[600px] xs:h-[500px] bg-white">
        <video style="border-radius: 15px" class="object-cover w-full h-full video" src="@/assets/sample.mp4"
          type="video/mp4" autoplay muted loop></video>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "_header",
  data() {
    return {
      prompts: 0,
      users: 0,
      social: [
        {
          icon: "fa fa-globe",
          link: "/chat",
          class: "bg-blue-500",
          title: "Try ToriChat",
          target: "_self",
        },
        {
          icon: "fab fa-whatsapp",
          link: "https://wa.me/+250737829991?text=Hey Tori",
          class: "bg-green-700",
          title: "Try on WhatsApp",
          target: "_blank",
        },
        {
          icon: "fab fa-telegram-plane",
          link: "https://t.me/toribyhova_bot",
          class: "bg-blue-500",
          title: "Try on Telegram",
          target: "_blank",

        },
      ],
    };
  },
  computed: {
    stats() {
      return [
        { title: "Prompts", value: this.prompts > 0 ? this.prompts : 20000 },
        { title: "Users", value: this.users > 0 ? this.users : 700 },
      ];
    },
  },
  mounted() {
    document.querySelector(".video").playbackRate = 2.0;
    this.$store.dispatch("getStats").then((res) => {
      this.prompts = res?.data?.total_prompts - 1000;
      this.users = res?.data?.total_users - 300;

      let prompts = setInterval(() => {
        this.prompts += 1;
        if (this.prompts === res?.data?.total_prompts) {
          clearInterval(prompts);
        }
      }, 1);

      let users = setInterval(() => {
        this.users += 1;
        if (this.users === res?.data?.total_users) {
          clearInterval(users);
        }
      }, 1);
    });
  },
});
</script>

<style lang="scss" scoped></style>
