<template>
  <div>
    <div class="text-center py-8">
      <div class="title text-[40px] xs:text-[25px] text-[#581845] font-bold">
        Some useful commands.
      </div>
      <div class="w-[50%] sm:w-full mx-auto my-5 text-gray-600 font-thin">
        "Tori has a collection of simple and useful commands that can help
        streamline your workflow and perform tasks more efficiently."
      </div>

      <div class="w-[50%] mx-auto sm:w-full sm:px-5 py-8">
        <div class="flex sm:flex-col shadow-lg text-black mb-5 border-l-4 border-gray-900" v-for="(item, i) in commands"
          :key="i">
          <div class="px-2 py-3 bg-gray-200 xs:text-[13px] md:w-[35%] text-left flex gap-1">
            <div class="bg-[#2a3942] rounded-2xl w-[90%] flex justify-between items-center px-3 h-8">
              <div class="flex items-center text-white text-[13px]">
                <span class="grayscale text-[20px]">😀</span>
                <span class="ml-1">{{ item.command }}</span>
              </div>
              <div class="text-white text-[13px]">
                <i class="fas fa-paperclip fa-rotate-270"></i>
              </div>
            </div>
            <a :href="`https://wa.me/+250737829991?text=${item.command}`">
              <div class="rounded-full bg-green-600 w-8 h-8 text-white flex items-center justify-center text-[12px]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                  stroke="currentColor" class="w-3 h-3">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                </svg>
              </div>
            </a>
          </div>

          <div class="pl-5 py-3 text-left">{{ item.meaning }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "_header",
  data() {
    return {
      commands: [
        {
          command: "tori reset",
          meaning: "To clear your chat history",
        },
        {
          command: "tori info",
          meaning: "To get your usage info",
        },
        {
          command: "tori voice",
          meaning: "To see available voices",
        },
        {
          command: "tori voice <name>",
          meaning: "To change Tori voice",
        },
        {
          command: "tori speak",
          meaning: "To turn on speech mode",
        },
        {
          command: "tori text",
          meaning: "To turn off speech mode",
        },
        {
          command: "tori incognito",
          meaning: "To turn on incognito mode",
        },
        {
          command: "tori incognito off",
          meaning: "To turn off incognito mode",
        },
        {
          command: "tori nuke",
          meaning: "To delete all your data",
        },
        {
          command: "tori help",
          meaning: "To get the help message",
        },
      ],
    };
  },
});
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.5s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-out;
}

.slide-fade-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
